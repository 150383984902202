export function jwtDecrypt(token: string) {
  if (!token) {
    return;
  }
  const base64 = token
    .split('.')[1]
    .replace(/-/g, '+')
    .replace(/_/g, '/');
  const payload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(char => {
        return '%' + ('00' + char.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(payload);
}
