import axios, { AxiosRequestConfig, Method } from 'axios';
import { loadingController,alertController } from '@ionic/vue';
import { toast } from '@/utils/toast';
import { Loading } from '@/utils/loading';
import store from '@/store';
import { alert } from '@/utils/alert';

const BASE_URL = '/api';
const loading = new Loading(loadingController);
const presentAlert = async () => {
  const alert = await alertController.create({
    header: '로그아웃',
    message: '로그인 기간 만료 및 다른 기기에서 접속으로 인해 로그아웃 됩니다.',
    buttons: [
      {
        text: '확인',
        handler: async () => {
       await store.dispatch('auth/logout');
     }
      }
  ],
  });

  await alert.present();
}
function getFileName(contentDisposition: string) {
  const fileName = contentDisposition
    .split(';')
    .filter((el: string | string[]) => {
      return el.indexOf('filename') > -1;
    })
    .map((el: string) => {
      return el
        .replace(/"/g, '')
        .replace(/'/g, '')
        .split('=')[1]
        .replace('UTF-8', '');
    });

  return fileName[0] ? fileName[0] : null;
}

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  const data = store.getters['auth/getData'];
  if (data == null) {
    return config;
  }

  config.headers.common['authorization'] = `${data.access.token}`;
  return config;
});

axios.interceptors.response.use(
  res => {
    return Promise.resolve(res);
  },
  err => {
    if (!err.response) {
      toast('네트워크에 접속할 수 없습니다. Wi-Fi 또는 데이터를 활성화 해주세요.', 2000);
    }

    switch (err.response.status) {
      case 400:
        toast('잘못된 요청입니다.', 2000);
        break;
      case 500:
        toast('서버와의 처리 과정에서 오류가 발생되었습니다. 잠시 후 다시 시도해주세요.', 2000);
        break;
      case 403:
        store.dispatch('auth/logout');
        presentAlert();
        break;

      default:
        break;
    }

    return Promise.reject(err);
  }
);
const logout = async () => {
  const alert = await alertController.create({
    header: '로그아웃',
    message: '로그인 기간 만료 및 다른 기기에서 접속으로 인해 로그아웃 됩니다.',
    buttons: [
      {
        text: '확인',
        handler: async () => {
       await store.dispatch('auth/logout');
     }
      }
  ],
  });

  await alert.present();
};
async function $api(url: string, method: Method, data?: object) {
  return await axios({
    method: method,
    url: BASE_URL + url,
    headers: {
      'Access-Control-Allo-Origin': '*',
      'Content-type': 'application/json'
    },
    data
  });
}

async function $upload(url: string, data?: object) {
  loading.present('로딩중...');
  return await axios
    .post(BASE_URL + url, data, {
      headers: {
        'Access-Control-Allo-Origin': '*',
        'Content-type': 'multipart/form-data'
      }
    })
    .then(async res => {
      await loading.dismiss();
      return Promise.resolve(res);
    })
    .catch(async err => {
      await loading.dismiss();
      return Promise.reject(err);
    });
}

async function $loading(url: string, method: Method, data?: object) {
  loading.present('로딩중...');

  return await axios({
    method: method,
    url: BASE_URL + url,
    headers: {
      'Access-Control-Allo-Origin': '*',
      'Content-type': 'application/json'
    },
    data
  })
    .then(async res => {
      await loading.dismiss();
      return Promise.resolve(res);
    })
    .catch(async err => {
      await loading.dismiss();
      return Promise.reject(err);
    });
}

async function $download(url: string) {
  const loading = new Loading(loadingController);

  loading.present('다운로드중...');

  return await axios({
    method: 'get',
    url: BASE_URL + url,
    headers: {
      'Access-Control-Allo-Origin': '*'
    },
    responseType: 'blob'
  })
    .then(res => {
      const blob = new Blob([res.data], { type: res.headers['content-type'] });
      let fileName = getFileName(res.headers['content-disposition']);

      if (fileName == null) {
        loading.dismiss();
        toast('파일이 존재하지 않습니다.');
        return;
      }

      fileName = decodeURI(fileName);

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_self';
      link.download = fileName;
      link.click();

      loading.dismiss();
    })
    .catch(err => {
      loading.dismiss();
      return Promise.reject(err);
    });
}

export { $api, $loading, $download, $upload };
