
import { IonButton, IonFooter } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BottomBtn',
  components: {
    IonButton,
    IonFooter
  },
  props: {
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    onClick() {
      this.$emit('@onClick');
    }
  }
});
