<template>
  <input
    class="sc-ion-input-md-h sc-ion-input-md"
    ref="inputRef"
    type="text"
    :value="formattedValue"
  />
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "CurrencyInput",
  props: {
    modelValue: Number,
    options: Object,
  },
  setup(props) {
    const { formattedValue, inputRef } = useCurrencyInput(props.options);

    return { inputRef, formattedValue };
  },
};
</script>