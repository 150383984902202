import { loadingController } from '@ionic/vue';

export class Loading {
  isLoading = false;
  constructor(public loading: typeof loadingController) {}

  async present(msg: string) {
    this.isLoading = true;
    return await this.loading
      .create({
        message: msg,
        backdropDismiss: true
      })
      .then(loader => {
        loader.present().then(() => {
          if (!this.isLoading) {
            loader.dismiss();
          }
        });
      });
  }

  async dismiss() {
    this.isLoading = false;

    return await this.loading.dismiss();
  }
}
