<template>
  <div class="info_wrap" :class="{ active: show }">
    <ion-label v-if="show" class="info_left" v-html="props.left" />
    <div class="info_right">
      <ion-badge color="primary">{{auth.name }}</ion-badge>
    </div>
  </div>
</template>

<script>
import { IonBadge, IonLabel } from '@ionic/vue';
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';

export default defineComponent({
  components: {
    IonBadge,
    IonLabel
  },
  props: {
    show: Boolean,
    left: String
  },
  setup(props) {
    const store = useStore();
    const auth = computed(() => store.getters['auth/getData']);

    return {
      props,
      auth
    };
  }
});
</script>

<style scoped>
.info_wrap {
  display: flex;
  justify-content: flex-end;
  border-bottom: 1px solid #ddd;
  height: 36px;
}
.active {
  justify-content: space-between !important;
}
.info_left {
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 0.8em;
}

.info_right {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.info_right p {
  margin: 0;
  padding-left: 5px;
}
ion-badge {
  padding: 5px 8px;
}
</style>