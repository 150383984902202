<template>
  <ion-fab slot="fixed" vertical="bottom" horizontal="end">
    <ion-fab-button @click="addItem">
      <ion-icon :icon="add" size="large" />
    </ion-fab-button>
  </ion-fab>
</template>

<script>
import { IonIcon, IonFab, IonFabButton } from '@ionic/vue';
import { add } from 'ionicons/icons';

export default {
  name: 'Header',
  components: {
    IonIcon,
    IonFab,
    IonFabButton
  },
  data() {
    return {
      add
    };
  },
  methods: {
    addItem() {
      this.$emit('@addItem');
    }
  }
};
</script>

<style scoped>
ion-fab {
  margin: 0.5em;
}
</style>