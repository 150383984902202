import { toastController } from '@ionic/vue';

export async function toast(msg: string, duration?: number): Promise<void> {
  duration = duration || 1500;
  const toast = await toastController.create({
    message: msg,
    duration: duration
  });

  return toast.present();
}
