
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonTitle,
  IonToolbar,
  alertController 
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
import { arrowBackOutline, logOutOutline } from 'ionicons/icons';
import { alert } from '@/utils/alert';

export default defineComponent({
  name: 'Header',
  components: {
    IonBackButton,
    IonButton,
    IonButtons,
    IonHeader,
    IonIcon,
    IonTitle,
    IonToolbar,
  },
  props: {
    title: {
      type: String,
      required: true
    },
    back: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const store = useStore();

    const logout = async () => {
        const alert = await alertController.create({
          header: '로그아웃',
          message: '로그아웃 하시겠습니까?',
          buttons: [
      {        
          text: '취소',
          role: 'cancel'
        },
            {
              text: '확인',
              handler: async () => {
             await store.dispatch('auth/logout');
           }
            }
        ],
        });

        await alert.present();
      };

      

    // async function logout() {
      
    //   return await alert('로그아웃', '로그아웃 하시겠습니까?', [
    //     {
    //       text: '취소',
    //       role: 'cancel'
    //     },
    //     {
    //       text: '확인',
    //       handler: async () => {
    //         await store.dispatch('auth/logout');
    //       }
    //     }
    //   ]);
    // }

    return {
      arrowBackOutline,
      logOutOutline,
      props,
      logout,
      
    };
  }
});
