<template>
  <ion-card button>
    <ion-item lines="none" :router-link="link">
      <ion-card-title>
        <slot name="badge"></slot>
        {{ title }}
      </ion-card-title>
      <ion-badge v-if="newCnt > 0" color="danger">{{ newCnt }}</ion-badge>
      <ion-icon slot="end" :icon="caretForward"/>
    </ion-item>
  </ion-card>
</template>

<script lang="ts">
import { IonCard, IonCardTitle, IonItem, IonIcon, IonBadge } from '@ionic/vue';
import { defineComponent } from 'vue';
import { caretForward } from 'ionicons/icons';

export default defineComponent({
  components: {
    IonCard,
    IonCardTitle,
    IonItem,
    IonIcon,
    IonBadge
  },
  props: {
    link: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    newCnt: {
      type: Number
    }
  },
  setup() {
    return {
      caretForward
    };
  }
});
</script>

<style scoped>
ion-item {
  --min-height: 55px;
  --padding-start: 1.5em;
  text-align: center;
}
ion-card {
  border-radius: 30px;
  margin-bottom: 1em;
}
ion-card-title {
  font-size: 1.2em;
}
ion-icon {
  font-size: 1.8em;
  margin: 0 0 0 5px;
  color: var(--ion-color-medium);
}
ion-badge {
  margin-left: 0.6em;
  padding: 0.3em 0.6em;
}
</style>