<template>
  <div class="warn-wrap">
    <ion-icon :icon="warningOutline" />
    <ion-label>네트워크에 접속할 수 없습니다.</ion-label>
    <ion-label>연결 상태를 확인 후 다시 시도해 주세요.</ion-label>
    <ion-button fill="outline" @click="onReConnect">재시도</ion-button>
  </div>
</template>

<script lang="ts">
import { IonButton, IonLabel, IonIcon } from '@ionic/vue';
import { defineComponent } from 'vue';
import { warningOutline } from 'ionicons/icons';

export default defineComponent({
  components: {
    IonButton,
    IonLabel,
    IonIcon
  },
  setup() {
    return {
      warningOutline
    };
  },
  methods: {
    onReConnect() {
      this.$emit('@onReConnect');
    }
  }
});
</script>

<style scoped>
.warn-wrap {
  margin-top: 10em;
}
ion-icon,
ion-label {
  color: rgb(66, 66, 66);
}
ion-icon {
  font-size: 3em;
  margin: 0.1em auto;
}
ion-label {
  display: block;
  margin: 0.2em 0;
}
ion-button {
  margin: 1em;
  min-height: 40px;
  min-width: 100px;
}
</style>