
import { IonButton, IonLabel, IonIcon } from '@ionic/vue';
import { defineComponent } from 'vue';
import { warningOutline } from 'ionicons/icons';

export default defineComponent({
  components: {
    IonButton,
    IonLabel,
    IonIcon
  },
  setup() {
    return {
      warningOutline
    };
  },
  methods: {
    onReConnect() {
      this.$emit('@onReConnect');
    }
  }
});
