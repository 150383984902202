<template>
  <div class="list-wrap">
    <ion-toolbar v-if="useSearch">
      <ion-searchbar v-model="keyword" :placeholder="placeholder" @keydown.enter="onSearch" />
    </ion-toolbar>
    <ion-content class="list-content">
      <ion-refresher
        slot="fixed"
        pull-factor="0.5"
        pull-min="100"
        pull-max="200"
        @ionRefresh="doRefresh($event)"
      >
        <ion-refresher-content />
      </ion-refresher>
      <template v-if="list.length > 0">
        <ion-list>
          <div v-for="(item, index) in list" :key="item" @click.prevent="itemClick(item, index)">
            <slot :item="item" />
          </div>
        </ion-list>
        <ion-infinite-scroll
          id="infinite-scroll"
          threshold="100px"
          :disabled="isDisabled"
          @ionInfinite="loadData($event)"
        >
          <ion-infinite-scroll-content loading-spinner="bubbles" />
        </ion-infinite-scroll>
      </template>
      <template v-else>
        <p class="no_data">게시물 없음</p>
      </template>
    </ion-content>
  </div>
</template>

<script>
import {
  IonContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonToolbar
} from '@ionic/vue';
export default {
  components: {
    IonContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonList,
    IonRefresher,
    IonRefresherContent,
    IonSearchbar,
    IonToolbar
  },
  props: {
    placeholder: {
      type: String,
      default: '항목 검색'
    },
    list: {
      required: true
    },
    limit: {
      type: Number,
      required: true
    },
    next: {
      type: Number,
      required: true
    },
    useSearch: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      keyword: '',
      isDisabled: false
    };
  },
  methods: {
    onSearch() {
      this.$emit('@onSearch', this.keyword);
    },
    async doRefresh(e) {
      this.isDisabled = false;
      await this.$emit('@setInitPage');
      await this.$emit('@doRefresh', this.keyword);
      e.target.complete();
      
    },
    async pushData() {
      await this.$emit('@getNextPage');
      await this.$emit('@pushData', this.keyword);
    },
    loadData(e) {
      // if all data is loaded and disable the infinite scroll
      if (Math.floor(this.next / this.limit) == 0) {
        this.isDisabled = true;
        e.target.disabled = true;
      } else {
        this.pushData();
        e.target.complete();
      }
    },
    itemClick(item, index) {
      this.$emit('@itemClick', item, index);
    }
  }
};
</script>

<style scoped>
ion-searchbar {
  text-align: left;
}
ion-toolbar ion-button {
  max-width: 50px;
}
.list-wrap {
  text-align: center;
  margin: 0 auto;
  padding: 10px 0;
  width: 95%;
}
.list-content {
  height: 80vh;
}
ion-item {
  width: 100%;
  --padding-start: 0px;
}
div {
  text-align: left;
}
</style>