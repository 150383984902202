import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';


import { IonicVue } from '@ionic/vue';
// import KakaoSdk from 'vue-kakao-sdk';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
import '@ionic/core/css/ionic.bundle.css'
/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* UI components */
import Header from './components/Header.vue';
import Menu from './components/Menu.vue';
import Info from './components/Info.vue';
import List from './components/List.vue';
import FabBtn from './components/FabBtn.vue';
import BottomBtn from './components/BottomBtn.vue';
import CurrencyInput from './components/CurrencyInput.vue';
import NetworkError from './components/NetworkError.vue';
import VCalendar from 'v-calendar';

/* Event Bus */
import mitt from 'mitt';
const emitter = mitt();

// const apiKey = '9fec5ab2d76922991fd668f895dd79ab';

const app = createApp(App)
  .use(IonicVue, {
    rippleEffect: false,
    mode: 'md'
  })
  .use(router)
  .use(store)
  .use(VCalendar, {});
  // .use(KakaoSdk, { apiKey });

app.provide('emitter', emitter);

app.component('AppHeader', Header);
app.component('MenuItem', Menu);
app.component('UserInfo', Info);
app.component('FabBtn', FabBtn);
app.component('BottomBtn', BottomBtn);
app.component('SearchList', List);
app.component('CurrencyInput', CurrencyInput);
app.component('NetworkError', NetworkError);


router.isReady().then(() => {
  app.mount('#app');
});
