
import { IonCard, IonCardTitle, IonItem, IonIcon, IonBadge } from '@ionic/vue';
import { defineComponent } from 'vue';
import { caretForward } from 'ionicons/icons';

export default defineComponent({
  components: {
    IonCard,
    IonCardTitle,
    IonItem,
    IonIcon,
    IonBadge
  },
  props: {
    link: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    newCnt: {
      type: Number
    }
  },
  setup() {
    return {
      caretForward
    };
  }
});
