<template>
  <ion-footer>
    <ion-button class="apply-btn" @click="onClick">{{ title }}</ion-button>
  </ion-footer>
</template>

<script lang="ts">
import { IonButton, IonFooter } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BottomBtn',
  components: {
    IonButton,
    IonFooter
  },
  props: {
    title: {
      type: String,
      required: true
    }
  },
  methods: {
    onClick() {
      this.$emit('@onClick');
    }
  }
});
</script>

<style scoped>
ion-footer {
  background-color: white;
  text-align: center;
}
.apply-btn {
  width: 97%;
}
</style>